<template>
  <div class="container-fluid">
    <div class="bg"></div>
    <div class="row L1">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 text-center">
            <h1>风光Pixel <span>影像日历</span></h1>
            <p>365张绝美照，365个镜头掠影，阅尽锦绣山河</p>
            <!-- <div class="ttt">
              <a href="https://mp.weixin.qq.com/s/c0FQ46NgsBtwInqXw6iprA" target="_blank" class="t-inbox">
                <b>NEW!</b> 2025年度壁纸投稿已开放！戳我投稿
              </a>
            </div> -->
            <div class="btnbox">
              <a class="btn" href="https://pan.baidu.com/s/1cdgmRjsr51in9mChZG8qrw&pwd=d5wy" target="_blank">度盘</a>
              <a class="btn" href="https://pan.quark.cn/s/13b1aa0ba6fe" target="_blank">夸克</a>
            </div>
          </div>
          <div class="col-xs-12">
            <div class="screenshot">
              <img  draggable="false" class="img-responsive" src="@/assets/img/landscapePixelWallpaper/1.gif" alt="" srcset="" >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row L2">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-md-6">
            <h1>举目之处皆为风景</h1>
            <p>全国百位优秀风光摄影师，他们带着相机的足迹遍布世界各地。每年，风光Pixel团队从中精选365张照片，集成在每年限量发售的《风光pixel365日历》中。错过现货发售的小伙伴，现在可以零距离体验到这些极致美图！即刻下载“风光Pxiel”，每天自动换一张绝美壁纸，让世间美景装入电脑，让日常的伏案工作变得更加沉浸。</p>
            <p>风光Pixel影像日历内置2022 ~ 2025年度壁纸集，我们会在每年更新壁纸库(无须重新下载软件)，喜欢的话，快分享给身边的朋友吧!</p>
          </div>
          <div class="col-xs-12 col-md-6">
            <img  draggable="false" class="img-responsive" src="@/assets/img/landscapePixelWallpaper/2.jpg" alt="" srcset="">
          </div>
        </div>
      </div>
    </div>
    <div class="row L3">
      <div class="col-xs-12">
        <img  draggable="false" class="img-responsive full-img" src="@/assets/img/landscapePixelWallpaper/4.jpg" alt="" srcset="">
      </div>
      <div class="container text">
        <div class="row">
          <div class="col-xs-12 text-center">
            <h1>风光大片如影随形</h1>
            <p>每天自动更换全屏壁纸，除此以外，还有拍摄地、器材、参数、作者、拍摄背景故事等信息，每天打开电脑都能发现全新机位和拍摄思路</p>
            <p>同时支持Windows、MacOS，让每台电脑都能享受绝美风光</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row Log">
      <div class="container">
        <div class="row">
          <div class="col-xs-12">
            <h1>版权声明</h1>
            <p>感谢您的关注，风光Pixel影像日历集合了数百位摄影师的精彩作品，每一幅作品的背后，都饱含了摄影师的汗水和泪水，风光Pixel影像日历中所使用的作品均已获得摄影师本人的使用授权，未经摄影师本人同意，任何第三方组织及个人均无权使用，违者必究。</p>

            <h1>软件说明</h1>
            <p>系统支持Windows 10+ 和 macOS 10.14.4+</p>
            <p>需要网络连接</p>

            <h1>更新日志</h1>
            <p>20240919 1.3.5 支持MacOS Sequoia(15.0.0)</p>
            <p>20231223 1.3.3 修复MacOS Sonoma(14.0.0)中无法更换壁纸的问题。该版本开始，本软件将只支持arm系统。</p>
            <p>20230104 1.3.2 修复界面问题</p>
            <p>20221225 1.3.0 增加日历界面</p>
            <p>20220124 1.2.4 修复mac上无法显示菜单栏图标的问题；修复部分日历说明文案超出边框的问题；</p>
            <p>20220124 1.2.2 增加启动提示</p>
            <p>20220124 1.2.0 调整日期算法，适配mac屏幕</p>
            <p>20220124 1.1.3 1080P显示器下字体放大；修复部分竖图显示错误的问题</p>
            <p>20220124 1.1.2 调整1080P显示器壁纸效果</p>
            <p>20220124 1.1.1 调整4K显示器壁纸效果</p>
            <p>20220124 1.1.0 支持多显示器，支持MAC OS</p>
            <p>20220122 1.0.1 更新界面</p>
            <p>20220122 1.0.0 第一版</p>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
export default {
  name: 'LandscapePixelWallpaper',
  metaInfo: {
    title: '风光Pixel 影像日历', // set a title
    meta: [{             // set meta
      name: 'keywords',
      content: '风光Pixel,风光摄影,日历,年历,台历,壁纸,wallpaper,风光照片,桌面背景'
    },
    {
      name: 'description',
      content: '每天为您的桌面更换一张绝美风光壁纸'
    }],
    link: []
  },
  props: {
    
  },
  computed:{

  },
  data(){
    return {
      publicPath: process.env.BASE_URL
    }
  },
  mounted(){
    
  },
  methods:{
    download(link){
      var a = document.createElement('a');
      a.href = link;//下载文件地址
      a.click(); 
    },
  }

}
</script>
<style lang="less" scoped>
	.flex{
		display: flex;
		align-items: center;
		justify-content: flex-start;	
	}
  .container-fluid{
    width: 100%;
    padding: 0;
    margin: 0;
    .bg{
      width: 100%;
      height: 800px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      background-image: linear-gradient(25deg, #13234b, #2f4e70, #497d97, #63b0c0);
    }
    .row{
      margin: 0;
      padding: 0;
    }
    .L1{
      color: #fff;
      padding-top: 100px;
      height: 1200px;
      h1{
        font-size: 64px;
        font-weight: 300;
        margin-top: 70px;
        span{
          font-size: 30px;
          white-space: nowrap;
        }
        @media (max-width: 970px) { 
          margin: 100px 0 50px;
        }
      }
      p{
        font-weight: 300;
      }
      .ttt{
        display: flex;
        align-items: center;
        justify-content: center;
        .t-inbox{
          width: 300px;
          font-size: 14px;
          line-height: 28px;
          --r: .5em;  /* control the cutout of the ribbon */
          --c: #ff4848;
          
          padding-inline: calc(.5em + var(--r));
          background-image: 
            linear-gradient(var(--c) 70%,#0000 0), 
            linear-gradient(to bottom left,#0000 50%, color-mix(in srgb,var(--c),#000 40%) 51% 84%,#0000 85%);
          background-position: 0 4px;
          background-size: 100% 28px;
          clip-path: polygon(0 4px,100% 4px,calc(100% - var(--r)) 14px,100% 24px,100% calc(100% - 4px),0 calc(100% - 4px),var(--r) calc(100% - 14px),0 calc(100% - 24px));
          color: #fff;
          text-decoration: none;
        }
      }
      .btnbox{
        text-align: center;
        margin: 60px 0;
        .btn{
          font-size: 16px;
          line-height: 1;
          padding: 8px 20px 8px;
          border: 1px solid #fff;
          color: #fff;
          border-radius: 20px;
          margin: 10px 0;
          display: inline-block;
          margin-right: 10px;
          &:hover{
            color: #46bdbd;
            background-color: #fff;
          }
        }
        a{
          color: #fff;
        }
        &.visible-xs{
          .btn{
            border: none;
            box-shadow: none;
            &:hover{
              background: transparent;
              color: #fff;
            }
          }
        }
      }
      .screenshot{
        width: 100%;
        overflow: hidden;
        text-align: center;
        padding-bottom: 50px;
        img{
          border-radius: 10px;
          width: 80%;
          max-width: 1200px;
          display: inline-block;
          box-shadow: 0 0 30px rgba(0,0,0,0.2);
        }
      }
    }
    .L2{
    }
    .L3{
      margin-top: 150px;
      .col-xs-12{
        padding: 0;
        .full-img{
          width: 100%;
        }
      }
      .text{
        width: 100vw;
        position: absolute;
        left: 50%;
        top: 90px;
        transform: translateX(-50%);
        user-select: none;
        h1{
          font-size: 60px;
        }
        h1,p{
          color: #fff;
          text-shadow: 0 0 20px rgba(0,0,0,0.5);
        }
      }
    }
    @media screen and (max-width:900px){
      .L1{
        height: 800px;
      }
      .L2{
        .panel{
          .panel-inbox{
            padding: 10px 20px;
          }
          h4{
            font-size: 28px;
          }
        }
      }
      .L3{
        height: 600px;
        .text{
          position: relative;
          top: 0;
          h1{
            font-size: 36px;
          }
          h1,p{
            color: #333;
            text-shadow: none;
          }
        }
      }
    }
  }
</style>